import { useEffect, useState } from "react";
import apiServices, { BASE_URL_IMG } from "../ApiServices/ApiServices";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserAddSher from "../User/Pages/UserAddPages/UserAddSher";
import UserAddShayari from "../User/Pages/UserAddPages/userAddShayari";
import UserAddProse from "../User/Pages/UserAddPages/UserAddProse";
import UserAddBlog from "../User/Pages/UserAddPages/UserAddBlog";
import UserBlogList from "../User/Pages/UserDataPages/UserViewBlog";
import UserSherList from "../User/Pages/UserDataPages/UserViewSher";
import UserProseList from "../User/Pages/UserDataPages/UserViewProse";
import UserShayariList from "../User/Pages/UserDataPages/UserViewShayari";
import UserOrderlist from "../User/Pages/UserDataPages/UVieworders";
import Clock from "../Admin/pages/Clock";

export default function Useruprofile() {
  const [id, setId] = useState(sessionStorage.getItem("_id"));

  const [customerData, setcustomerData] = useState();
  const [name, setName] = useState();
  const [penname, setPenName] = useState();
  const [email, setEmail] = useState();
  const [address, setAddress] = useState();
  const [password, setPassword] = useState();
  const [newpassword, setNewPassword] = useState();
  const [confirmpassword, setConfirmPassword] = useState();
  const [contact, setContact] = useState();
  const [Image, setImage] = useState();
  const [bio, setBio] = useState();
  const [facebook, setFacebook] = useState();
  const [linkdin, setLinkdin] = useState();
  const [twiter, setTwiter] = useState();
  const [instagram, setInstagram] = useState();

  const [Totalshayari, setTotalshayari] = useState()
  const [Totalsher, setTotalsher] = useState()
  const [Totalprose, setTotalprose] = useState()
  const [Totalblog, setTotalblog] = useState()
  const [viewtab, setTabview] = useState(1);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [viewtab])

  const changeimage = (e) => {
    setImage(e.target.files[0]);
  };

  useEffect(() => {
    setId(sessionStorage.getItem("_id"));

    let data = {
      userId: id,
    };

    apiServices
      .getsinglecustomer(data)
      .then((data) => {
        if (data.data.success) {
          setcustomerData(data.data.data);
          setName(data.data.data.name);
          setPenName(data.data.data.penname);
          setEmail(data.data.data.email);
          setAddress(data.data.data.address);
          setContact(data.data.data.contact);
          setBio(data.data.data.bio);
          setFacebook(data.data.data.facebook);
          setLinkdin(data.data.data.linkdin);
          setTwiter(data.data.data.twiter);
          setTwiter(data.data.data.instagram);
        } else {
          toast.error(data.data.message);
        }
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });


    apiServices.dashboard(data).then((x) => {
      setTotalshayari(x.data.usertotal_shayari)
      setTotalsher(x.data.usertotal_sher)
      setTotalprose(x.data.usertotal_prose)
      setTotalblog(x.data.usertotal_blog)
    }).catch((error) => {
      toast.error("Something went wrong");
    })
  }, []);

  const handleupdateData = (e) => {
    e.preventDefault();

    let data = new FormData();

    data.append("name", name);
    data.append("penname", penname);
    data.append("email", email);
    data.append("address", address);
    data.append("contact", contact);
    data.append("Image", Image);
    data.append("bio", bio);
    data.append("facebook", facebook);
    data.append("linkdin", linkdin);
    data.append("twiter", twiter);
    data.append("instagram", instagram);
    data.append("_id", id);

    apiServices
      .updateuser(data)
      .then((data) => {
        if (data.data.success) {
          toast.success(data.data.message);

        } else {
          toast.error(data.data.message);
        }
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
  };

  const handlepassword = (e) => {
    e.preventDefault();

    let data = {
      oldpassword: password,
      newpassword: newpassword,
      confirmpassword: confirmpassword,
      userId: id,
    };

    apiServices
      .changepassword(data)
      .then((data) => {
        if (data.data.success) {
          toast.success(data.data.message);
        } else {
          toast.error(data.data.message);
        }
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
  };

  return (
    <>
      <div className="sidebardivider">

        <div className="sideBarOptionWrap">
          <input type="checkbox" id="check" />
          <label className="button bars sidebarnav-label hidebg" for="check">
            <i className="fas fa-bars"></i>
          </label>
          <div className="user-panel-sidebar">
            <div className="user-panel-title">
              <div className="user-panel-logo">Welcome</div>
              <label
                className=" button cancel sidebarnav-label crossicon"
                for="check"
              >
                <i className="fas fa-times"></i>
              </label>
            </div>
            <ul className="Dssidebarlist">
              <li onClick={() => setTabview(1)} className={`${viewtab === 1 && 'active'}`}>
                <a href="#">
                  <i className="fas fa-qrcode"></i>Dashboard
                </a>
              </li>
              <li onClick={() => setTabview(2)} className={`${viewtab === 2 && 'active'}`}>
                <a href="#">
                  <i className="fas fa-link"></i>Publish blog
                </a>

              </li>
              <li onClick={() => setTabview(3)} className={`${viewtab === 3 && 'active'}`}>
                <a href="#">
                  <i className="fas fa-link"></i>Publish Sher
                </a>
              </li>
              <li onClick={() => setTabview(4)} className={`${viewtab === 4 && 'active'}`}>
                <a href="#">
                  <i className="fas fa-link"></i>Publish Shayari
                </a>
              </li>
              <li onClick={() => setTabview(5)} className={`${viewtab === 5 && 'active'}`}>
                <a href="#">
                  <i className="fas fa-link"></i>Publish Prose
                </a>
              </li>
              <li onClick={() => setTabview(6)} className={`${viewtab === 6 && 'active'}`}>
                <a href="#">
                  <i className="fa-solid fa-eye"></i>My blog
                </a>
              </li>
              <li onClick={() => setTabview(7)} className={`${viewtab === 7 && 'active'}`}>
                <a href="#">
                  <i className="fa-solid fa-eye"></i>My Sher
                </a>
              </li>
              <li onClick={() => setTabview(8)} className={`${viewtab === 8 && 'active'}`}>
                <a href="#">
                  <i className="fa-solid fa-eye"></i>My Shayari
                </a>
              </li>
              <li onClick={() => setTabview(9)} className={`${viewtab === 9 && 'active'}`}>
                <a href="#">
                  <i className="fa-solid fa-eye"></i>My Prose
                </a>
              </li>
              <li onClick={() => setTabview(10)} className={`${viewtab === 10 && 'active'}`}>
                <a href="#">
                  <i className="fa-solid fa-eye"></i>My orders
                </a>
              </li>
              <li onClick={() => setTabview(11)} className={`${viewtab === 11 && 'active'}`}>
                <a href="#">
                  <i className="fa-solid fa-eye"></i>Profile
                </a>
              </li>
            </ul>
            <div className="user-panel-media_icons">
              <a href="#">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="#">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="#">
                <i className="fab fa-youtube"></i>
              </a>
            </div>
          </div>
        </div>

        <section className={`section uprofile my-5 mith ${viewtab === 11 && 'active'}`}>
          <div className="row">
            <div className='main-title'>
              <Clock />
            </div>
            <div className="col-xl-4">
              <div className="card mb-3">
                <div className="card-body uprofile-card pt-4 d-flex flex-column align-items-center">
                  <img
                    src={BASE_URL_IMG + customerData?.Image}
                    alt="uprofile"
                    className="rounded-circle  img-fluid uprofile-side-img"
                  />
                  <h2>{customerData?.name}</h2>
                  <h3>{customerData?.bio}</h3>
                  <div className="social-links mt-2">
                    <a href="#" className="twitter">
                      <i className="bi bi-twitter"></i>
                    </a>
                    <a href="#" className="facebook">
                      <i className="bi bi-facebook"></i>
                    </a>
                    <a href="#" className="instagram">
                      <i className="bi bi-instagram"></i>
                    </a>
                    <a href="#" className="linkedin">
                      <i className="bi bi-linkedin"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-8">
              <div className="card">
                <div className="card-body pt-3">
                  <ul className="nav nav-tabs nav-tabs-bordered">
                    <li className="nav-item">
                      <button
                        type="submit"
                        className="nav-link active changepassbtn"
                        data-bs-toggle="tab"
                        data-bs-target="#uprofile-overview"
                      >
                        Overview
                      </button>
                    </li>

                    <li className="nav-item">
                      <button
                        type="button"
                        className="nav-link changepassbtn"
                        data-bs-toggle="tab"
                        data-bs-target="#uprofile-edit"
                      >
                        Edit Profile
                      </button>
                    </li>

                    <li className="nav-item">
                      <button
                        type="button"
                        className="nav-link changepassbtn"
                        data-bs-toggle="tab"
                        data-bs-target="#uprofile-change-password"
                      >
                        Change Password
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content pt-2">
                    <div
                      className="tab-pane fade show active uprofile-overview"
                      id="uprofile-overview"
                    >
                      <h5 className="card-title">uprofile Details</h5>

                      < div className="row">
                        < div className="col-lg-3 col-md-4 label ">
                          Full Name
                        </div>
                        <div className="col-lg-9 col-md-8">
                          <a className="uprifile-name">{customerData?.name}</a>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-3 col-md-4 label ">
                          Pen Name
                        </div>
                        <div className="col-lg-9 col-md-8">
                          <a className="uprifile-name">{customerData?.penname}</a>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-3 col-md-4 label">Address</div>
                        <div className="col-lg-9 col-md-8">
                          {customerData?.address}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-3 col-md-4 label">Phone</div>
                        <div className="col-lg-9 col-md-8">
                          (+91) {customerData?.contact}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-3 col-md-4 label">Email</div>
                        <div className="col-lg-9 col-md-8">
                          {customerData?.email}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3 col-md-4 label">Facebook</div>
                        <div className="col-lg-9 col-md-8">
                          {customerData?.facebook}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3 col-md-4 label">Twiter</div>
                        <div className="col-lg-9 col-md-8">
                          {customerData?.twiter}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3 col-md-4 label">Linkdin</div>
                        <div className="col-lg-9 col-md-8">
                          {customerData?.linkdin}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3 col-md-4 label">Instagram</div>
                        <div className="col-lg-9 col-md-8">
                          {customerData?.instagram}
                        </div>
                      </div>
                    </div>

                    <div
                      className="tab-pane fade uprofile-edit pt-3"
                      id="uprofile-edit"
                    >
                      <form onSubmit={handleupdateData}>
                        <div className="row mb-3">
                          <label
                            for="uprofileImage"
                            className="col-md-4 col-lg-3 col-form-label"
                          >
                            Profile Image
                          </label>
                          <div className="col-md-8 col-lg-9">
                            <img
                              src={BASE_URL_IMG + customerData?.Image}
                              alt="uprofile"
                              className="img-fluid"
                            />
                            <div className="pt-2">
                              <input
                                type="file"
                                className="form-control"
                                id="file"
                                onChange={(e) => {
                                  changeimage(e);
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row mb-3">
                          <label
                            for="fullName"
                            className="col-md-4 col-lg-3 col-form-label"
                          >
                            Full Name
                          </label>
                          <div className="col-md-8 col-lg-9">
                            <input
                              type="text"
                              className="form-control"
                              id="fullName"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <label
                            for="fullName"
                            className="col-md-4 col-lg-3 col-form-label"
                          >
                            Pen Name
                          </label>
                          <div className="col-md-8 col-lg-9">
                            <input
                              type="text"
                              className="form-control"
                              id="fullName"
                              value={penname}
                              onChange={(e) => setPenName(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <label
                            for="bio"
                            className="col-md-4 col-lg-3 col-form-label"
                          >
                            Bio
                          </label>
                          <div className="col-md-8 col-lg-9">
                            <input
                              type="text"
                              className="form-control"
                              id="fullName"
                              value={bio}
                              onChange={(e) => setBio(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <label
                            for="Address"
                            className="col-md-4 col-lg-3 col-form-label"
                          >
                            Address
                          </label>
                          <div className="col-md-8 col-lg-9">
                            <input
                              type="text"
                              className="form-control"
                              id="Address"
                              value={address}
                              onChange={(e) => setAddress(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <label
                            for="Phone"
                            className="col-md-4 col-lg-3 col-form-label"
                          >
                            Phone
                          </label>
                          <div className="col-md-8 col-lg-9">
                            <input
                              type="text"
                              className="form-control"
                              id="Phone"
                              value={contact}
                              onChange={(e) => setContact(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <label
                            for="Email"
                            className="col-md-4 col-lg-3 col-form-label"
                          >
                            Email
                          </label>
                          <div className="col-md-8 col-lg-9">
                            <input
                              type="email"
                              className="form-control"
                              id="Email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <label
                            for="facebook"
                            className="col-md-4 col-lg-3 col-form-label"
                          >
                            Facebook
                          </label>
                          <div className="col-md-8 col-lg-9">
                            <input
                              type="text"
                              className="form-control"
                              id="facebook"
                              value={facebook}
                              onChange={(e) => setFacebook(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <label
                            for="linkdin"
                            className="col-md-4 col-lg-3 col-form-label"
                          >
                            Linkdin
                          </label>
                          <div className="col-md-8 col-lg-9">
                            <input
                              type="text"
                              className="form-control"
                              id="linkdin"
                              value={linkdin}
                              onChange={(e) => setLinkdin(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <label
                            for="twiter"
                            className="col-md-4 col-lg-3 col-form-label"
                          >
                            Twiter
                          </label>
                          <div className="col-md-8 col-lg-9">
                            <input
                              type="text"
                              className="form-control"
                              id="twiter"
                              value={twiter}
                              onChange={(e) => setTwiter(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <label
                            for="instagram"
                            className="col-md-4 col-lg-3 col-form-label"
                          >
                            Instagram
                          </label>
                          <div className="col-md-8 col-lg-9">
                            <input
                              type="text"
                              className="form-control"
                              id="instagram"
                              value={instagram}
                              onChange={(e) => setInstagram(e.target.value)}
                            />
                          </div>
                        </div>


                        <div className="text-center">
                          <button type="submit" className="btn changepassbtn2">
                            Save Changes
                          </button>
                        </div>
                      </form>
                    </div>

                    <div
                      className="tab-pane fade pt-3"
                      id="uprofile-change-password"
                    >
                      <form onSubmit={handlepassword}>
                        <div className="row mb-3">
                          <label
                            for="currentPassword"
                            className="col-md-4 col-lg-3 col-form-label"
                          >
                            Current Password
                          </label>
                          <div className="col-md-8 col-lg-9">
                            <input
                              type="password"
                              className="form-control"
                              id="currentPassword"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <label
                            for="newPassword"
                            className="col-md-4 col-lg-3 col-form-label"
                          >
                            New Password
                          </label>
                          <div className="col-md-8 col-lg-9">
                            <input
                              type="password"
                              className="form-control"
                              id="newPassword"
                              value={newpassword}
                              onChange={(e) => setNewPassword(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <label
                            for="renewPassword"
                            className="col-md-4 col-lg-3 col-form-label"
                          >
                            Re-enter New Password
                          </label>
                          <div className="col-md-8 col-lg-9">
                            <input
                              type="password"
                              className="form-control"
                              id="renewPassword"
                              value={confirmpassword}
                              onChange={(e) =>
                                setConfirmPassword(e.target.value)
                              }
                            />
                          </div>
                        </div>

                        <div className="text-center">
                          <button type="submit" className="btn changepassbtn2">
                            Change Password
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className={`section uprofile mith ${viewtab === 2 && 'active'}`}>
          <UserAddBlog />
        </section>
        <section className={`section uprofile mith ${viewtab === 3 && 'active'}`}>
          <UserAddSher />
        </section>
        <section className={`section uprofile mith ${viewtab === 4 && 'active'}`}>
          <UserAddShayari />
        </section>
        <section className={`section uprofile mith ${viewtab === 5 && 'active'}`}>
          <UserAddProse />
        </section>
        <section className={`section uprofile mith ${viewtab === 6 && 'active'}`}>
          <UserBlogList />
        </section>
        <section className={`section uprofile mith ${viewtab === 7 && 'active'}`}>
          <UserSherList />
        </section>
        <section className={`section uprofile mith ${viewtab === 8 && 'active'}`}>
          <UserShayariList />
        </section>
        <section className={`section uprofile mith ${viewtab === 9 && 'active'}`}>
          <UserProseList />
        </section>
        <section className={`section uprofile mith ${viewtab === 10 && 'active'}`}>
          <UserOrderlist />
        </section>

        <section className={`section uprofile mith ${viewtab === 1 && 'active'}`}>
          <div className="container-fluid">
            <div className='row   d-flex mt-3'>
              <Clock className='col  text-start ' />
              <h1 className="col text-end  d-flex ">Welcome, <p className="upnametext">{customerData?.name}</p></h1>
            </div>

            <div className="row py-3">
              <div className="col-md-4 mb-3">
                <div className="card">
                  <div className="card-header bg-primary text-white">Total Sher</div>
                  <div className="card-body">
                    <h3 className="card-title">{Totalsher}</h3>
                    {/* <p className="card-text">View All  Sher .</p> */}
                    <a href="#" onClick={() => setTabview(7)} className={`btn btn-primary ${viewtab === 7 && 'active'}`}>View Total Sher</a>
                  </div>
                </div>
              </div>

              <div className="col-md-4 mb-3">
                <div className="card">
                  <div className="card-header bg-success text-white">Total Shayari</div>
                  <div className="card-body ">
                    <h3 className="card-title">{Totalshayari}</h3>
                    {/* <p className="card-text">View All  Shayari </p> */}
                    <a href="#" onClick={() => setTabview(8)} className={`btn btn-success ${viewtab === 8 && 'active'}`}>View Total Shayari</a>
                  </div>
                </div>
              </div>

              <div className="col-md-4 mb-3">
                <div className="card">
                  <div className="card-header bg-danger text-white"> Total Prose</div>
                  <div className="card-body">
                    <h3 className="card-title">{Totalprose}</h3>
                    {/* <p className="card-text">View All  Prose .</p> */}
                    <a href="#" onClick={() => setTabview(9)} className={`btn btn-danger ${viewtab === 9 && 'active'}`}>View Total Prose</a>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-3">
                <div className="card">
                  <div className="card-header bg-warning text-white" > Total Blog</div>
                  <div className="card-body">
                    <h3 className="card-title">{Totalblog}</h3>
                    {/* <p className="card-text">View All  Blog .</p> */}
                    <a href="#" onClick={() => setTabview(6)} className={`btn btn-warning text-white ${viewtab === 6 && 'active'}`}>View Total Blog</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <ToastContainer />
    </>
  );
}
