import { useEffect } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import apiServices, { BASE_URL_IMG } from "../../../ApiServices/ApiServices";
import { useState } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";

export default function Poets() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [Totaldashboard, setDashboard] = useState(null)
  const [Totaldashboardtwo, setDashboardtwo] = useState(null)
  const [Totaldashboardthree, setDashboardthree] = useState(null)
  // const [UserId, setUserId] = useState([])
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [activeBtn, setActiveBtn] = useState('')

  const override = {
    display: "block",
    position: "absolute",
    top: "25%",
    left: "48%",
    zIndex: "1",
  };

  const fetchData = async () => {
    try {
      const response = await apiServices.getallcustomer();

      if (response.data.success) {
        setUsers(response.data.data);

        const userIds = response.data.data.map((user) => user.userId._id);

        apiServices.usershayariDash({ userIds }).then((x) => {
          setDashboard(x.data.shayariCounts);
        });

        apiServices.userproseDash({ userIds }).then((x) => {
          setDashboardtwo(x.data.proseCounts);
        });

        apiServices.usersherDash({ userIds }).then((x) => {
          setDashboardthree(x.data.sherCounts);
        });
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong" + error);
    }
    
    setLoading(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3500);
    fetchData();
  }, []);

  const handleSearchQueryChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const performSearch = (query) => {
    const filteredResults = users.filter((user) => {
      const fullName = user.name + user.penname;
      return fullName.toLowerCase().includes(query.toLowerCase());
    });

    setSearchResults(filteredResults);
  };

  const handleSearch = (e) => {
    e.preventDefault();

    if (searchQuery === '') {
      setSearchResults(users);
    }
    else {
      performSearch(searchQuery)
    }
  };

  const handleAlphabeticalSearch = (alphabet) => {
    setActiveBtn(alphabet)
    performSearch(alphabet)
  }

  return (
    <>
      <ScaleLoader loading={loading} cssOverride={override} size={70} />
      <div className={loading ? "disable-full-screen" : ""}>
        <section className="waviy-body">
          <div className="waviy">
            <h1 >OUR<span className="px-3">POETS</span></h1><hr className="headinghr" />
          </div>
        </section>

        <section className="searchBar">
          <div className="search-1 ">
            <form onSubmit={handleSearch}>
              <input type="search" placeholder="Search" value={searchQuery}
                onChange={handleSearchQueryChange} required="" />
              <input type="submit" value="." />
            </form>
          </div>
        </section>

        <div className="alphabeticalSearch">
          <ul className="clearfix">
            <li className="poetAlphLtr" onClick={() => handleAlphabeticalSearch('a')} style={(activeBtn === 'a') ? { backgroundColor: "#FFCC00", color: "white" } : {}}>
              <a aria-label="A" title="A" className="IndexActive">A</a>
            </li>
            <li className="poetAlphLtr" onClick={() => handleAlphabeticalSearch('b')} style={(activeBtn === 'b') ? { backgroundColor: "#FFCC00", color: "white" } : {}}><a aria-label="B" title="B" className="">B</a></li>
            <li className="poetAlphLtr" onClick={() => handleAlphabeticalSearch('c')} style={(activeBtn === 'c') ? { backgroundColor: "#FFCC00", color: "white" } : {}}><a aria-label="C" title="C" className="">C</a></li>
            <li className="poetAlphLtr" onClick={() => handleAlphabeticalSearch('d')} style={(activeBtn === 'd') ? { backgroundColor: "#FFCC00", color: "white" } : {}}><a aria-label="D" title="D" className="">D</a></li>
            <li className="poetAlphLtr" onClick={() => handleAlphabeticalSearch('e')} style={(activeBtn === 'e') ? { backgroundColor: "#FFCC00", color: "white" } : {}}><a aria-label="E" title="E" className="">E</a></li>
            <li className="poetAlphLtr" onClick={() => handleAlphabeticalSearch('f')} style={(activeBtn === 'f') ? { backgroundColor: "#FFCC00", color: "white" } : {}}><a aria-label="F" title="F" className="">F</a></li>
            <li className="poetAlphLtr" onClick={() => handleAlphabeticalSearch('g')} style={(activeBtn === 'g') ? { backgroundColor: "#FFCC00", color: "white" } : {}}><a aria-label="G" title="G" className="">G</a></li>
            <li className="poetAlphLtr" onClick={() => handleAlphabeticalSearch('h')} style={(activeBtn === 'h') ? { backgroundColor: "#FFCC00", color: "white" } : {}}><a aria-label="H" title="H" className="">H</a></li>
            <li className="poetAlphLtr" onClick={() => handleAlphabeticalSearch('i')} style={(activeBtn === 'i') ? { backgroundColor: "#FFCC00", color: "white" } : {}}><a aria-label="I" title="I" className="">I</a></li>
            <li className="poetAlphLtr" onClick={() => handleAlphabeticalSearch('j')} style={(activeBtn === 'j') ? { backgroundColor: "#FFCC00", color: "white" } : {}}><a aria-label="J" title="J" className="">J</a></li>
            <li className="poetAlphLtr" onClick={() => handleAlphabeticalSearch('k')} style={(activeBtn === 'k') ? { backgroundColor: "#FFCC00", color: "white" } : {}}><a aria-label="K" title="K" className="">K</a></li>
            <li className="poetAlphLtr" onClick={() => handleAlphabeticalSearch('l')} style={(activeBtn === 'l') ? { backgroundColor: "#FFCC00", color: "white" } : {}}><a aria-label="L" title="L" className="">L</a></li>
            <li className="poetAlphLtr" onClick={() => handleAlphabeticalSearch('m')} style={(activeBtn === 'm') ? { backgroundColor: "#FFCC00", color: "white" } : {}}><a aria-label="M" title="M" className="">M</a></li>
            <li className="poetAlphLtr" onClick={() => handleAlphabeticalSearch('n')} style={(activeBtn === 'n') ? { backgroundColor: "#FFCC00", color: "white" } : {}}><a aria-label="N" title="N" className="">N</a></li>
            <li className="poetAlphLtr" onClick={() => handleAlphabeticalSearch('o')} style={(activeBtn === 'o') ? { backgroundColor: "#FFCC00", color: "white" } : {}}><a aria-label="O" title="O" className="">O</a></li>
            <li className="poetAlphLtr" onClick={() => handleAlphabeticalSearch('p')} style={(activeBtn === 'p') ? { backgroundColor: "#FFCC00", color: "white" } : {}}><a aria-label="P" title="P" className="">P</a></li>
            <li className="poetAlphLtr" onClick={() => handleAlphabeticalSearch('q')} style={(activeBtn === 'q') ? { backgroundColor: "#FFCC00", color: "white" } : {}}><a aria-label="Q" title="Q" className="">Q</a></li>
            <li className="poetAlphLtr" onClick={() => handleAlphabeticalSearch('r')} style={(activeBtn === 'r') ? { backgroundColor: "#FFCC00", color: "white" } : {}}><a aria-label="R" title="R" className="">R</a></li>
            <li className="poetAlphLtr" onClick={() => handleAlphabeticalSearch('s')} style={(activeBtn === 's') ? { backgroundColor: "#FFCC00", color: "white" } : {}}><a aria-label="S" title="S" className="">S</a></li>
            <li className="poetAlphLtr" onClick={() => handleAlphabeticalSearch('t')} style={(activeBtn === 't') ? { backgroundColor: "#FFCC00", color: "white" } : {}}><a aria-label="T" title="T" className="">T</a></li>
            <li className="poetAlphLtr" onClick={() => handleAlphabeticalSearch('u')} style={(activeBtn === 'u') ? { backgroundColor: "#FFCC00", color: "white" } : {}}><a aria-label="U" title="U" className="">U</a></li>
            <li className="poetAlphLtr" onClick={() => handleAlphabeticalSearch('v')} style={(activeBtn === 'v') ? { backgroundColor: "#FFCC00", color: "white" } : {}}><a aria-label="V" title="V" className="">V</a></li>
            <li className="poetAlphLtr" onClick={() => handleAlphabeticalSearch('w')} style={(activeBtn === 'w') ? { backgroundColor: "#FFCC00", color: "white" } : {}}><a aria-label="W" title="W" className="">W</a></li>
            <li className="poetAlphLtr" onClick={() => handleAlphabeticalSearch('y')} style={(activeBtn === 'y') ? { backgroundColor: "#FFCC00", color: "white" } : {}}><a aria-label="Y" title="Y" className="">Y</a></li>
            <li className="poetAlphLtr" onClick={() => handleAlphabeticalSearch('z')} style={(activeBtn === 'z') ? { backgroundColor: "#FFCC00", color: "white" } : {}}><a aria-label="Z" title="Z" className="">Z</a></li>
          </ul>
        </div>

        <div className="container my-5">
          <div className="row">
            <div className="col m-3">
              {searchResults.length > 0
                ? searchResults.map((data, index) => (
                  <></>
                ))
                : users.map((data, index) => (
                  <></>
                ))}
              <div className="row">
                {searchResults.length > 0
                  ? searchResults.map((data, index) => (
                    <div className="col-lg-3 poetwraper" key={data._id}>
                      <Link to={"/poets-profile/" + `${data?.userId?._id}`} >
                        <div className="card poetcard poetbg-primary card-hover poetmb-9">
                          <div className="card-body text-center px-md-5 px-lg-5 my-2">
                            <div className="card-icon-border-large poetborder-primary mtn-80">
                              <img
                                src={BASE_URL_IMG + data?.Image || "/assets/images/avtar.png"}
                                className=" img-fluid imgz"
                                alt="img..."
                                onError={(e) => {
                                  e.target.src = "/assets/images/avtar.png";
                                }}
                              />
                            </div>
                          </div>
                          <div className=" blockquote  pt-2 d-flex justify-content-center ">
                            <div className=" text-center poetcard-name fw-bold text-capitalize">  @{data?.name}
                              {data?.bedgeverify ? (
                                <img
                                  src="/quality.png"
                                  alt="Verified Badge"
                                  className="badgeimagz"
                                />
                              ) : null}
                            </div>
                          </div>
                          <div className=" poetcard-footer d-flex justify-content-around">
                            {Totaldashboardthree && (
                              <div className="text-center   "><h5>Sher</h5>
                                {Totaldashboardthree[data.userId._id] || 0}
                              </div>
                            )}
                            <div className="poetcard-footer-border"></div>
                            {Totaldashboard && (
                              <>
                                <div className=" text-center  "> <h5>Ghazal</h5>
                                  {Totaldashboard[data.userId._id] || 0}
                                </div>
                              </>
                            )}
                            <div className="poetcard-footer-border"></div>
                            {Totaldashboardtwo && (
                              <div className=" text-center "><h5>Nazm</h5>
                                {Totaldashboardtwo[data.userId._id] || 0}
                              </div>
                            )}
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))
                  : users.map((data, index) => (
                    <div className="col-lg-3 poetwraper" key={data?._id}>
                      <Link to={"/poets-profile/" + `${data?.userId?._id}`} >
                        <div className="card rounded poetcard poetbg-primary card-hover poetmb-9">
                          <div className="card-body text-center px-md-5 px-lg-5 my-2">
                            <div className="card-icon-border-large poetborder-primary mtn-80">
                              <img
                                src={BASE_URL_IMG + data?.Image || "/assets/images/avtar.png"}
                                className=" img-fluid imgz"
                                alt="img..."
                                onError={(e) => {
                                  e.target.src = "/assets/images/avtar.png";
                                }}
                              />
                            </div>
                          </div>
                          <div className=" blockquote  pt-2 d-flex justify-content-center ">
                            <div className=" text-center poetcard-name fw-bold text-capitalize">  @{data?.name}
                              {data?.bedgeverify ? (
                                <img
                                  src="/quality.png"
                                  alt="Verified Badge"
                                  className="badgeimagz"
                                />
                              ) : null}
                            </div>
                          </div>
                          <div className=" poetcard-footer d-flex justify-content-around">
                            {Totaldashboardthree && (
                              <div className="text-center"><h5>Sher</h5>
                                {Totaldashboardthree[data.userId._id] || 0}
                              </div>
                            )}
                            <div className="poetcard-footer-border"></div>
                            {Totaldashboard && (
                              <>
                                <div className=" text-center  "> <h5>Ghazal</h5>
                                  {Totaldashboard[data.userId._id] || 0}
                                </div>
                              </>
                            )}
                            <div className="poetcard-footer-border"></div>
                            {Totaldashboardtwo && (
                              <div className=" text-center "><h5>Nazm</h5>
                                {Totaldashboardtwo[data.userId._id] || 0}
                              </div>
                            )}
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
